import React, { useContext } from "react";
import { useAccordionToggle, AccordionContext } from 'react-bootstrap'

const ContextAwareToggle = ({ children, eventKey, callback }) => {
    const currentEventKey = useContext(AccordionContext);
  
    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey),
    );
  
    const isCurrentEventKey = currentEventKey === eventKey;
  
    return (
        <ul>
            <li className={isCurrentEventKey ? 'active' : ''}>
                <button
                    type="button"
                    className={isCurrentEventKey ? 'active' : ''}
                    onClick={decoratedOnClick}
                >
                    {children}
                </button>
            </li>
      </ul>
    );
}

export default ContextAwareToggle